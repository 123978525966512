import React from 'react'
import PrivateLayout from '../../layouts/private'
import MessageOfTheDay from '../../components/pages/MessageOfTheDay/pages'

const MessageOfTheDayPage = () => {
  return <PrivateLayout><MessageOfTheDay /></PrivateLayout>
}

MessageOfTheDayPage.propTypes = {

}

MessageOfTheDayPage.defaultProps = {

}

export default MessageOfTheDayPage
