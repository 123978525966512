import React from 'react'
import CurrentMOTD from '../CurrentMOTD'
import MessageOfTheDayLayout from '../layout'

const MessageOfTheDay = () => {
  return <MessageOfTheDayLayout>
    <CurrentMOTD />
  </MessageOfTheDayLayout>
}

MessageOfTheDay.propTypes = {

}

MessageOfTheDay.defaultProps = {

}

export default MessageOfTheDay
